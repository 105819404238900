.compage-image {
    display: flex;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.5);
    width: 100vw;
    height: 450px;
    position: relative;
    z-index: -1;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    background-size: cover; /* Use cover to fill the container */
    background: black;
    filter: brightness(70%)

}

  .header-overlay {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    color: #fff;
    font-size: 2.75rem;
    font-weight: 500;
    align-items: center;
    width: 70%;
    font-family: 'Poppins';
}
  
.com-name{
    font-size: 1.75rem;
    font-weight: 500;
    text-align: center;
    align-self: center;
    margin: 5vh;
    width: 80%;
}

.description{
    width: 75%;
    align-self: center;
    line-height: 30px;
}

.guide-btn{
    color: black;
    display: flex;
    width: 250px;
    height: 55px;
    border-radius: 15px;
    border-color: #00000000;
    background: rgb(152, 188, 244);
    font-weight: 500;
    font-size: 15px;
    transition: width 0.4s ease-out, height 0.4s ease-out, font-size 0.4s ease-out;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-decoration: none;
    margin: 5vh;
    text-align: center;
  }

  .guide-btn:hover{
    width: 270px;
    height: 57px;
    font-size: 16.5px;
  }

.chair-box{
    /* width: 45vw; */
    display: flex;
    
}

  .chairs{
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    align-items: center;
    margin: 5vh;
}

.chairs-bio{
    font-size: .9rem;
    font-weight: normal;
    line-height: 25px;
    width: 33vw;
    text-align: left;
    margin-bottom: 1rem;
}

.photo{
    width: 25vw;
    height: 30vw;
    object-fit: cover;
    margin: 20px;
    size: contain;
}

.chair-container{
    display: flex;
}

.position{
    font-size: 1.5rem;
    margin: 10px;
    margin-top: 0;
    margin-bottom: 1rem;
}

.back-button{
    color: black;
    display: flex;
    width: 250px;
    height: 55px;
    border-radius: 15px;
    border-color: #00000000;
    background: rgb(152, 188, 244);
    font-weight: 500;
    font-size: 15px;
    transition: width 0.4s ease-out, height 0.4s ease-out, font-size 0.4s ease-out;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-decoration: none;
    margin: 5vh;
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .chair-container{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .chair-box{
        width: 80vw;
        display: flex;
        flex-direction: column;
    }

    .chairs-bio{
        font-size: 1rem;
        font-weight: normal;
        line-height: 30px;
        width: 80vw;
    }

    .photo{
        width: 50vw;
        height: 60vw;
        object-fit: cover;
        margin: 20px;
        size: contain;
    }
    
}