.number-container {
    display: flex;
    align-items: center;
  }
  
  .statistics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px; /* Ensure the container takes the full height of the viewport */
  }
  
  .image {
    overflow: hidden;
    width: 100vh; /* Set the width to 100% of the viewport height */
    height: 100%; /* Set the height to 100% of its container */
    position: absolute;
    z-index: -1;
}

.image {
    display: flex;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .3);
    width: 100%;
    height: 500px;
    position: relative;
    z-index: -1;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    background: url('./images/berkeley-home.jpg') center center;
    background-size: cover; /* Use cover to fill the container */
}

.hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; /* Add this to position the image container */
}

.home-intro {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #fff;
    font-size: 5rem;
    /* left: 100px; */ /* Remove this line */
}

.home-intro p {
    margin-bottom: 17px;
    font-size: 1.5rem;
}

.sec-letter {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    color: rgb(0, 0, 0);
    text-align: left;
    flex-wrap: wrap;
}

.signature {
    font-family: 'Alex Brush';
    font-size: 35px;
    margin-top: 0;
}

.home-info {
    display: flex;
    flex-direction: row;
}

.home-text {
    margin-left: 5vw;
    display: flex;
    flex-direction: column;
}

.letter-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
}

.contact-info {
    text-align: center;
    color: #fff;
    font-family: 'Playfair Regular 400 Italic', serif;
    font-weight: lighter;
    font-size: 0.9rem;
    /* left: 100px; */ /* Remove this line */
    margin-top: 35px;
}

.contact-info p {
    margin-bottom: 17px;
}

.ucbmun-image {
    width: 25vw;
    height: auto;
    margin-bottom: 20px;
}

.countdown-container{
    align-items: center;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.countdown{
    color: white;
    align-items: center;
    font-size: 2.75rem;
    max-width: 400;
    
}

.home-section {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.home-time{
    color: white;
    font-size: 1rem;
    max-width: 4700px;
    display: flex;
}

.words{
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1360px) {
    .move2{
        display: none;
    }
}

@media screen and (max-width: 1360px) {
    .move{
        display: none;
    }
}
  

@media screen and (max-width: 860px) {

    .home-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .home-text{
        margin-top: 5vh;;
        margin-left: 0;
        width: 80vw;
    }
    /* .intro{
        text-align: center;
        color: #fff;
        font-family: 'Playfair Display Regular 400 Italic', serif;
        font-style: italic;
        font-weight: lighter;
        font-size: 0.9rem;
        left: 100px;
        margin-top: -20px;
    }

    .contact-info{
        text-align: center;
        color: #fff;
        font-family: 'Playfair Display Regular 400 Italic', serif;
        font-weight: lighter;
        text-shadow: inset 0 0 0 1000px rgba(0,0,0,0);
        font-size: 0.9rem;
        margin-top: -40px;
    }
    
    .contact-info p{
        margin-bottom: 17px;
        color: #fff;
        font-weight: lighter;
    }
    .hero-container {
        background: url('./images/ocean-image.jpg') center center/cover no-repeat;
        width: 100%;
        left: 000px;
        display: flex;
        flex-direction: column;
        box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
    } */
}