
.title{
    text-align: center;
}

.container {
    align-content: center;
}

.intro{
    text-align: left;
    color: #fff;
    font-size: 5rem;
    left: 100px;
    margin-top: 20vh;
}

.intro p{
    margin-bottom: 17px;
    font-size: 1.5rem;
    /* color: #fff; */
    /* font-weight: lighter; */
}


.policy-info{
    width: 75vw;
    text-align: left;
}


.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
} 

.policies{
    align-items: center;
    align-self: center;
    align-content: center;
    text-align: center;
}

.policy{
text-align: left;
color: rgb(0, 0, 0);
  font-size: 25px;
  font-weight: 800;
}

.content{
    font-size: 0.9rem;
}

/* @media screen and (max-width: 760px) {
    .intro{
        text-align: center;
        color: #fff;
        font-family: 'Playfair Display Regular 400 Italic', serif;
        font-style: italic;
        font-weight: lighter;
        font-size: 0.9rem;
        left: 100px;
        margin-top: -20px;
    }

    .contact-info{
        text-align: center;
        color: #fff;
        font-family: 'Playfair Display Regular 400 Italic', serif;
        font-weight: lighter;
        text-shadow: inset 0 0 0 1000px rgba(0,0,0,0);
        font-size: 0.9rem;
        margin-top: -40px;
    }
    
    .contact-info p{
        margin-bottom: 17px;
        color: #fff;
        font-weight: lighter;
    }
    .hero-container {
        background: url('./images/ocean-image.jpg') center center/cover no-repeat;
        width: 100%;
        left: 000px;
        display: flex;
        flex-direction: column;
        box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
    }
} */