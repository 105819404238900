
.title{
    text-align: center;
}

.container {
    /* background: url('./images/ocean-image.jpg') center center/cover no-repeat; */
    width: 100%;
    left: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
}


.header{
    text-align: center;
    color: #fff;
    font-size: 4.5rem;
    left: 100px;
  
  }

.us-image {
    object-fit: cover;
    overflow: hidden;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
    width: 100%;
    height: 450px; /* Set the height to 100% of its container */
    position: relative;
    z-index: -1;
    background: url('./images/berkeley-home.jpg') center center;
    background-size: cover; /* Use cover to fill the container */
  }

.intro{
    text-align: center;
    color: #fff;
    font-size: 5rem;
    left: 100px;
    margin-top: 20vh;
}

.letter p{
    width: 70vw;
    /* color: #fff; */
    /* font-weight: lighter; */
}


.page-title{
text-align: center;
}

.day{
    display: flex;
    flex-direction: column;
    width: 40vw;
    align-items: center;
}

.building{
    display: flex;
    flex-direction: column;
    width: 20vw;
    align-items: center;
}

.times{
    display: flex;
    flex-direction: row;
    text-align: center;
}

.schedule{
    display: flex;
    /* flex-direction: row; */
}


.room{
    display: flex;
    flex-direction: column;
    width: 40vw;
    align-items: center;
}

.location{
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    width: 80vw;

}

.info{
    display: flex;
    flex-direction: row;
}

.text{
    margin-left: 5vw;
    width: 40vw;
    display: flex;
    flex-direction: column;
}

.room-box{
    display: flex;
    flex-direction: column;
    width: 20vh;
    align-items: center;
}

.room-box p{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.room-box-ceremony p{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
}


.sec-gen-image{
    width: 300px;
    height: 300px;
}

.contact-info{
    text-align: center;
    color: #fff;
    font-family: 'Playfair Regular 400 Italic', serif;
    font-weight: lighter;
    font-size: 0.9rem;
    left: 100px;
    margin-top: 35px;
}

.letter{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.contact-info p{
    margin-bottom: 17px;
    /* color: #fff; */
    /* font-weight: lighter; */
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
} 

.ucbmun-image{
    width: 25vw; /* Adjust the width as needed */
    height: auto; /* This will maintain the aspect ratio */
    margin-bottom: 20px; /* Adjust the margin as needed */

}

.time-box{
    display: flex;
    flex-direction: column;
}

.time-box p{
    width: auto;
}

.box{
    display: flex;
    width: 20rem;
    justify-content: space-between;
}

.location-box{
    display: flex;
    justify-content: space-between;
}
  

@media screen and (max-width: 1060px) {
    .schedule{
        flex-direction: column;
        align-items: center;
    }

    .location{
        flex-direction: column;
        align-items: center;
    }

    .day{
        width: 70vw;
        margin-bottom: 5vh;
    }

    .room-box-ceremony p{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40vw;
    }
}