.footer-container {
    background: rgb(195, 207, 227);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 100%;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #000000;
    font-size: 15px;
  }
  
  .social-media {
    width: 100%;
    align-items: center;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 50px;
    margin: 00px auto 0 auto;
    margin: auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    /* margin-left: 18px; */
    margin-top: 10px;
  }
  
  .social-logo {
    color: #000000;
    /* justify-self: start; */
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .website-rights {
    color: #000000;
    margin-bottom: 16px;
    font-size: 17px;
    text-align: center;
    margin-top: 12px;
  }

  .overall-footer{
    align-items: center;
    margin: auto;
    text-align: center;
  }

  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }