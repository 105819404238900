.cards {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items in the center horizontally */
}

.individual-cards{
  width: 70vw;
  display: flex;
  flex-direction: row;
  margin-bottom: 10vh;
}

.cards__item__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  text-align: left; /* Left align text */
}

.cards__items {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  text-align: left; /* Left align text */
  align-items: center;

}

.header{
  text-align: center;
  color: #fff;
  font-size: 4.5em;
  left: 100px;

}

.cards__item__img {
  width: 25vw;
  height: 17vw;
  object-fit: cover;
  margin-bottom: 20px; /* Adjust as needed */
  size: contain; /* Resize the image to fit within the container */

}

.cards__item__text {
  color: #252e48;
  font-size: 14px;
  line-height: 22px;
  text-align: left; /* Left align text */
  margin-top: 8px;
  width: 45vw;
  margin-left: 10%;
  color: rgb(0, 0, 0);
}

.title {
  color: #252e48;
  font-size: 20px;
  line-height: 50px;
  text-align: left;
  margin-left: 10%;
  font-weight: 450;
  color: rgb(0, 0, 0);
}

.email {
  color: #252e48;
  font-size: 17px;
  line-height: 30px;
  text-align: left;
  margin-top: 8px;
  margin-left: 10%;
  color: rgb(0, 0, 0);
  font-style: italic;
}

.name {
  text-align: left; /* Left align text */
  color: rgb(0, 0, 0);
  font-size: 30px;
  font-weight: 800;
  margin-left: 10%;
  margin-bottom: 2%;
}

.bio-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

.us-image {
  display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    overflow: hidden;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
    width: 100%;
    height: 450px; /* Set the height to 100% of its container */
    position: relative;
    z-index: -1;
    background: url('./images/berkeley-home.jpg') center center;
    background-size: cover; /* Use cover to fill the container */
}

/* .rows{
  display: flex;
  flex-direction: row;
  width: 90%;
} */

  
  @media only screen and (max-width: 860px) {
    .content__blog__container {
      width: 84%;
    }
    
      .individual-cards{
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        
      }

      .cards__item__inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin: auto; */
    }

    .cards__item__img {
      width: 65vw;;
      height: 48vw;
    }

    .cards__item__text,
    .email,
    .name,
    .title{
      align-content: center;
      align-self: center;
      text-align: center;
      margin-left: 0;
    }

    .cards__item__text,
    .bio-text{
      width: 80vw;
    }
  
}
