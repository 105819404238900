.committees {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items in the center horizontally */
  }
  
  .individual1{
    width: 50vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .individual2{
    width: 50vw;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }


  .committees__item__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    text-align: left; /* Left align text */
  }
  
  .committee__items {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start; /* Align items to the left */
    text-align: left; /* Left align text */
    justify-content: space-between;
    margin-top: 10vh;
    /* align-items: center; */
  
  }

  .com-link{
    margin-top: 8vh;
  }
  
  .header{
    text-align: center;
    color: #fff;
    font-size: 3.5rem;
    left: 100px;
    font-weight: 500;
    align-items: center;
  }
 
  .text-box-right{
    position: relative;
    align-content: center;
    margin-right: -5vw;
    width: 45vw;
    height: auto;
    padding: 1rem;
    background: rgb(195, 207, 227);
  }

  .text-box-left{
    position: relative;
    align-content: center;
    margin-left: -5vw;
    width: 45vw;
    height: auto;
    padding: 1rem;
    background: rgb(195, 207, 227);
  }
  
  .committees__item__img {
    width: 35vw;
    height: 40vh;
    object-fit: cover;
    size: contain; /* Resize the image to fit within the container */
  
  }
  
  .committees__item__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Align items to the left */
    
  }
  
  .title {
    color: #252e48;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    font-weight: 450;
    color: rgb(0, 0, 0);
  }
  

  .list{
    display: flex;
    flex-direction: column;
    margin-right: -20px;
    /* align-items: center; */
  }

.add-level{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 100px;
  text-decoration: none;
  color: rgb(130, 130, 130);
  font-size: 1rem;
  margin-top: 10px;
  /* width: 200px; */
}

.add-level:hover {
  color: black;
  font-size: 1.05rem;
  transition: font-size 0.5s ease; /* Only transition font size */
}


  .level {
    color: #252e48;
    font-size: 1.75rem;
    line-height: 30px;
    text-align: left;
    margin-top: 8px;
    color: rgb(0, 0, 0);
    font-style: italic;
    font-weight: 400;
    width: 250px;

  }
  
  .committee {
    text-align: left; /* Left align text */
    color: rgb(0, 0, 0);
    font-size: 1.2rem;
    font-weight: 800;
    width: 95%;
    /* display: flex;
    flex-direction: row; */
  }

  .com-image {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    overflow: hidden;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
    width: 100%;
    height: 450px; /* Set the height to 100% of its container */
    position: relative;
    z-index: -1;
    background: url('./images/committee.jpg') center center;
    background-size: cover; /* Use cover to fill the container */
  }

  /* .rows{
    display: flex;
    flex-direction: row;
    width: 90%;
  } */

  .back-btn{
    color: black;
    display: flex;
    width: 160px;
    height: 45px;
    border-radius: 35px;
    border-color: #00000000;
    background: rgb(152, 188, 244);
    font-weight: 500;
    font-size: 15px;
    transition: width 0.4s ease-out, height 0.4s ease-out, font-size 0.4s ease-out;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
  }
  
  .back-btn:hover{
    width: 170px;
    height: 47px;
    font-size: 16px;
  }

  .skill{
    text-align: left; /* Left align text */
    color: rgb(100, 100, 100);
    font-size: 0.75rem;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 85%;
  }

  .com-icon {
   display: none;
  }

.com-menu li {
  list-style-type: none;
}

/* Remove the mobile-specific styles and keep the default styles for desktop */
.com-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  opacity: 1;
  transition: all 0.5s ease-out;
  z-index: 1;
  align-content: center;
  text-align: left;
  margin-top: 20px;
}

.com-menu.active {
  left: 0;
  opacity: 0.9;
  transition: all 0.5s ease;
  height: auto; /* Changed height to auto for mobile */
}

    
    @media only screen and (max-width: 900px) {
      .committees {
        display: flex;
        flex-direction: column;
        align-items: center; /* Align items in the center horizontally */
      }
      
      .individual1{
        width: 50vw;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    
      .individual2{
        width: 50vw;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
      }
      .committees__item__inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
        text-align: left; /* Left align text */
      }
      
      .committee__items {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
        text-align: left; /* Left align text */
        margin-top: 0vh;
      }

      .com-link{
        width: 100%;
        margin-top: 0;
        margin-bottom: 50px;
      }
      
      .header{
        text-align: center;
        color: #fff;
        left: 100px;
        font-weight: 500;
      }
     
      .text-box-right{
        position: relative;
        align-content: center;
        margin-right: -5vw;
        width: 45vw;
        height: auto;
        padding: 1rem;
        background: rgb(195, 207, 227);
      }
    
      .text-box-left{
        position: relative;
        align-content: center;
        margin-left: -5vw;
        width: 45vw;
        height: auto;
        padding: 1rem;
        background: rgb(195, 207, 227);
      }
      
      .committees__item__img {
        width: 50vw;
        height: 40vh;
        object-fit: cover;
        size: contain; /* Resize the image to fit within the container */
      
      }
      
      .committees__item__text {
        color: #252e48;
        font-size: 14px;
        line-height: 22px;
        text-align: left; /* Left align text */
        width: 30vw;
        color: rgb(0, 0, 0);
      }
      
      .title {
        color: #252e48;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        font-weight: 450;
        color: rgb(0, 0, 0);
      }
      
    
      .list{
        display: flex;
        flex-direction: column;
        width: 80vw;
        margin-right: 0;
        /* margin-top: 10vh; */
        /* align-items: center; */
      }
    
    .add-level{
      display: flex;
      flex-direction: column;
      /* align-items: center; */

    }
    
      .level {
        align-self: center;
        text-align: center;
        margin-bottom: 5vh;
        width: 100%;
      }
      
      .committee {
        text-align: left; /* Left align text */
        color: rgb(0, 0, 0);
        font-size: 1.25rem;
        font-weight: 800;
        /* display: flex;
        flex-direction: row; */
      }

    
      .com-icon {
        display: flex; /* Show menu icon for mobile */
        font-size: 1.25rem;
        color: #000000;
        margin-left: 10px;
      }
      
      .com-menu {
        display: none;
      }
      
      /* Keep the default styles for desktop */
      .com-menu.active {
        display: block;
      }
      
    }
    @media only screen and (max-width: 650px){

      .back-btn{
        width: 120px;
      }
      
      .back-btn:hover{
        width: 125px;
        height: 47px;
        font-size: 16px;
      }
  }
  